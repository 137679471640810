<template>
  <CommonLayout class="game-item">
    <template #default>
      <router-link
        class="button-default button-back control-back"
        :to="{ name: 'home' }"
        ><span></span>Назад</router-link
      >
      <button
        @click="
          $router.push({ name: 'home' });
          $modal.show('tutorial');
        "
        class="control-learn button button-default"
      >
        Обучение
      </button>
      <Loading v-if="loading" position="center" theme="game" />
      <canvas ref="island" width="1920" height="900"></canvas>
      <section v-if="islandData" class="board">
        <div class="board__inner">
          <div class="board-head">
            <div class="board-head__group">
              <div class="board-head__title">
                {{ islandDataStatic.title }}
              </div>
              <button @click="openIntro" class="board-head__button">
                Легенда планеты
              </button>
            </div>
          </div>
          <div class="board-body">
            <div class="board-body__title">Статистика планеты:</div>
            <div class="board-body__list">
              <ul>
                <li>
                  <span>Спрятанные капсулы</span><i></i
                  ><b>{{ islandData.remainingQuests }}</b>
                </li>
                <li>
                  <span>Найденные задания</span><i></i
                  ><b>{{ islandData.openedQuests }}</b>
                </li>
                <li>
                  <span
                    >Получено <b>{{ COINS }}</b></span
                  ><i></i
                  ><strong
                    >{{ islandData.acquiredCoins | priceFilter }}
                    <img
                      src="@/assets/img/pic2/coin.svg"
                      width="12"
                      height="12"
                      alt=""
                  /></strong>
                </li>
              </ul>
            </div>
          </div>
          <div class="board-foot">
            <div class="board-foot__caption">
              На этой неделе вам осталось найти:
              <strong>{{ remainingQuestsThisWeek }}</strong>
              <br />
              Чтобы взять задание, нужно нажать на ячейку планеты
            </div>
          </div>
        </div>
      </section>
    </template>

    <template #modals>
      <div class="modals">
        <ModalTaskChests
          v-on:close="closeChests"
          v-on:openedQuest="openedCellQuest"
          v-on:openedEmpty="openedCellEmpty"
        />
        <ModalTaskQuestion v-on:close="closeModalTask" />
        <ModalTaskTest v-on:close="closeModalTask" />
        <ModalTaskKPI v-on:close="closeModalTask" />
        <ModalTaskBusiness v-on:close="closeModalTask" />
        <ModalTaskResult />

        <ModalIslandBefore />
        <ModalIslandAfter />
      </div>
    </template>
  </CommonLayout>
</template>

<script>
import ModalTaskChests from "@/components/ModalTaskChests.vue";
import ModalTaskQuestion from "@/components/ModalTaskQuestion.vue";
import ModalTaskTest from "@/components/ModalTaskTest.vue";
import ModalTaskKPI from "@/components/ModalTaskKPI.vue";
import ModalTaskBusiness from "@/components/ModalTaskBusiness.vue";
import ModalTaskResult from "@/components/ModalTaskResult.vue";

import ModalIslandBefore from "@/components/ModalIslandBefore.vue";
import ModalIslandAfter from "@/components/ModalIslandAfter.vue";

import { islandList } from "@/data/game";
import CommonLayout from "@/layout/CommonLayout.vue";
import { fabric } from "fabric";
import { mapGetters } from "vuex";
import { declOfNum } from "@/utils";
import { OpenCell, GetIslandByIndex } from "@/api/Player";
import { GetQuestByID } from "@/api/PlayerQuests";

// в дизайне есть несостыковка острова от края полотна
const ISLAND_OFFSET_LEFT = 0;

export default {
  props: ["name"],
  components: {
    CommonLayout,
    ModalTaskChests,
    ModalTaskQuestion,
    ModalTaskTest,
    ModalTaskKPI,
    ModalTaskBusiness,
    ModalTaskResult,
    ModalIslandBefore,
    ModalIslandAfter,
  },
  computed: {
    ...mapGetters([
      "profileStatus",
      "getProfile",
      "getProfileCurrentIsland",
      "getGame",
    ]),
    loading() {
      return this.profileStatus === "loading";
    },
    totalQuests() {
      let quest = 8;

      if (this.islandData.haveCaptainQuest) {
        quest++;
      }

      return quest;
    },
    openedQuests() {
      return this.totalQuests - this.islandData.openedQuests;
    },
    remainingQuestsThisWeek() {
      const count = this.islandData.remainingQuestsThisWeek;
      const decl = declOfNum(this.islandData.remainingQuestsThisWeek, [
        "задание",
        "задания",
        "заданий",
      ]);
      return `${count} ${decl}`;
    },
  },
  watch: {
    profileStatus(status) {
      if (status === "success") {
        this.isAccess();
      }
    },
  },
  data() {
    return {
      canvas: null,
      captainMarker: null,
      islandIndex: null,
      islandCompleted: false,
      islandDataStatic: null,
      islandData: null,
      cellList: [],
    };
  },
  created() {
    this.addClassBody();
  },
  mounted() {
    if (!this.loading) {
      this.isAccess();
    }
  },
  destroyed() {
    this.removeClassBody();
  },
  methods: {
    isAccess() {
      if (this.canvas) {
        return false;
      }
      islandList.forEach((island, index) => {
        if (island.name === this.name) {
          if (index <= this.getProfileCurrentIsland.islandIdx) {
            this.islandIndex = index;
            this.init();
          } else {
            this.$router.push({ name: "home" });
          }
        }
      });
    },
    init() {
      const island = this.$refs.island;
      const options = {
        // backgroundColor: "#000",
        enableRetinaScaling: false,
        hoverCursor: "pointer",
        selection: false,
      };

      this.canvas = new fabric.Canvas(island, options);
      this.islandDataStatic = islandList[this.islandIndex];

      // this.drawBackground();

      // инициализируем 16 ячеек для острова
      for (let index = 0; index < this.ISLAND_CELLS_AMOUNT; index++) {
        this.cellList[index] = null;
      }

      // если остров прошедший, полностью закрашиваем и показываем аутро
      if (this.islandIndex < this.getProfileCurrentIsland.islandIdx) {
        this.islandCompleted = true;
        // this.completeAllCell();
      }

      // если игра завершена, открываем все острова и показываем аутро(в том числе для последнего острова)
      if (this.getGame.isGameOver) {
        this.islandCompleted = true;
      }

      this.drawBackground();

      GetIslandByIndex({ islandIdx: this.islandIndex })
        .then((resp) => {
          this.islandData = resp.data;

          if (this.islandCompleted) {
            this.openAutro();
            this.completeAllCell();
          } else {
            this.addCells(this.islandIndex);
            this.handleCellHover();
            this.handleCellClick();
          }
        })
        .catch((err) => {
          this.$modal.show("message", {
            message: err.data,
          });
        });
    },
    fetchData() {
      GetIslandByIndex({ islandIdx: this.islandIndex }).then((resp) => {
        this.islandData = resp.data;
      });
    },
    drawBackground() {
      const fileName = this.islandCompleted ? "full" : "bg";

      this.canvas.setBackgroundImage(
        `/static/img/game/island/${this.name}/${fileName}.png`,
        this.canvas.renderAll.bind(this.canvas),
        {
          left: ISLAND_OFFSET_LEFT,
        }
      );
      // fabric.Image.fromURL(`/static/img/game/island/bg_water.png`, oImg => {
      //   oImg.set({
      //     selectable: false,
      //     hoverCursor: "default"
      //   });
      //   this.canvas.add(oImg).sendToBack(oImg);
      // });
    },
    addCells() {
      let openedCells = 0;

      this.islandData.cells.forEach((cell) => {
        switch (cell.cellState) {
          // ячейка открыта
          case 0:
            this.addCellArea(cell.cellIndex);
            break;
          // ячейка закрашена
          case 1:
            this.drawCellCompleted(cell.cellIndex);
            openedCells++;
            break;
        }
      });

      if (!openedCells) {
        this.openIntro();
      }
    },
    drawCellCompleted(index) {
      const number = ("0" + (index + 1)).slice(-2);
      let cell = {};

      if (this.islandData) {
        cell = this.islandData.cells[index];
      }

      fabric.Image.fromURL(
        `/static/img/game/island/${this.name}/PNG/${number}.png`,
        (oImg) => {
          oImg.set({
            selectable: false,
            hoverCursor: "default",
            left: ISLAND_OFFSET_LEFT,
          });
          this.canvas.add(oImg).sendToBack(oImg);
        }
      );

      if (cell.isCaptainsQuest) {
        const srcPath = this.islandDataStatic.cells[index].path;
        const path = new fabric.Path(srcPath);
        this.addCaptainMarker(path);
      }
    },
    completeCell(area, index) {
      this.drawCellCompleted(index);

      if (area) {
        this.canvas.remove(area);
        this.removeCaptainMarker(area);
      }

      this.cellList[index] = null;
    },
    completeAllCell() {
      // this.cellList.forEach((cell, index) => {
      //   this.completeCell(cell, index);
      // });
    },
    removeCell(cell, index) {
      this.canvas.remove(cell);
      this.removeCaptainMarker(cell);
      this.cellList[index] = null;
    },
    removeAllCells() {
      this.cellList.forEach((cell, index) => {
        if (cell) {
          this.removeCell(cell, index);
        }
      });
    },
    addCellArea(cellIndex) {
      const srcPath = this.islandDataStatic.cells[cellIndex].path;
      const path = new fabric.Path(srcPath);
      const transform = this.islandDataStatic.pathsTransform;

      let options = {
        key: cellIndex,
        area: true,
        opacity: 0.000000000001,
        visible: true,
        selectable: false,
        fill: "#5CFFED",
        perPixelTargetFind: true,
      };

      path.set(options);
      path.set({ left: path.left + ISLAND_OFFSET_LEFT });

      if (transform) {
        path.set({ left: path.left - transform.left });
        path.set({ top: path.top - transform.top });
      }

      this.canvas.add(path);
      this.cellList[cellIndex] = path;
    },
    addCaptainMarker(path) {
      // берем позиционирование и размер area,
      // вставляем маркер по центру area
      let offsetTop = 50;
      let offsetLeft = 40;
      let top = path.top + (path.height / 2 - offsetTop);
      let left = path.left + ISLAND_OFFSET_LEFT + (path.width / 2 - offsetLeft);
      let width = 46 * 1.2;
      let height = 64 * 1.2;

      fabric.Image.fromURL(`/static/img/game/captain.svg`, (oImg) => {
        oImg.set({
          key: path.key,
          selectable: false,
          hoverCursor: "default",
          evented: false,
          top: top,
          width: width,
          height: height,
          left: left,
        });
        this.canvas.add(oImg);
        this.captainMarker = oImg;
      });
    },
    removeCaptainMarker(area) {
      if (this.captainMarker) {
        if (area.key === this.captainMarker.key) {
          this.canvas.remove(this.captainMarker);
        }
      }
    },
    handleCellHover() {
      this.canvas.on("mouse:over", (e) => {
        if (e.target && e.target.area) {
          e.target.set("opacity", 0.5);
          this.canvas.renderAll();
        }
      });

      this.canvas.on("mouse:out", (e) => {
        if (e.target && e.target.area) {
          e.target.set("opacity", 0.000000000001);
          this.canvas.renderAll();
        }
      });
    },
    handleCellClick() {
      this.canvas.on("mouse:up", (e) => {
        if (e.target && e.target.area) {
          // alert(e.target.key);
          // this.completeCell(e.target, e.target.key);

          // // todo тестить открывание ячеек
          // this.drawCellCompleted(e.target.key);
          // return;

          // если есть задания на эту неделю, открываем ячейку
          if (this.islandData.remainingQuestsThisWeek > 0) {
            this.openCell(e.target, e.target.key);
            return;
          }

          // если это капитанское задание, сразу открываем задание
          // без выбора сундуков
          // if (this.captainMarker) {
          //   if (this.captainMarker.key === e.target.key) {
          //     this.openCaptainCell(e.target, e.target.key);
          //     return;
          //   }
          // }

          this.$modal.show("message", {
            message: "На этой неделе у Вас нет заданий",
          });
        }
      });
    },
    openCell(target, cellIndex) {
      this.$modal.show("chests", {
        cellIndex: cellIndex,
        target: target,
      });
    },
    openCaptainCell(target, cellIndex) {
      OpenCell({ cellIndex: cellIndex })
        .then((resp) => {
          if (resp.data.hasQuest) {
            this.$emit("openedQuest", {
              target: target,
              cellIndex: cellIndex,
              resp: resp.data,
            });
          } else {
            this.$emit("openedEmpty", {
              target: target,
              cellIndex: cellIndex,
            });
          }
        })
        .catch(this.handleError);
    },
    openIntro() {
      this.$modal.show("islandBefore", { name: this.name });
    },
    openAutro() {
      setTimeout(() => {
        this.$modal.show("islandAfter", {
          name: this.name,
        });
      }, 200);
    },
    removeClassBody() {
      const el = document.body.classList;

      islandList.forEach((island) => {
        el.remove(`island-${island.name}`);
      });
    },
    addClassBody() {
      this.removeClassBody();
      document.body.classList.add(`island-${this.name}`);
    },
    openedCellQuest(data) {
      this.completeCell(data.target, data.cellIndex);

      GetQuestByID({ questId: data.resp.questId })
        .then((resp) => {
          const questType = resp.data.questType;

          switch (questType) {
            case 0: // Kpi
              this.$modal.show("kpi", {
                data: resp.data,
                type: "kpi",
                hideClose: true,
              });
              break;
            case 1: // Test
              this.$modal.show("test", { questId: resp.data.id });
              break;
            case 2: // Question
              this.$modal.show("question", { questId: resp.data.id });
              break;
            case 3: // OffGame
              this.$modal.show("kpi", {
                data: resp.data,
                type: "offGame",
                hideClose: true,
              });
              break;
            case 4: // Business
              this.$modal.show("business", {
                data: resp.data,
                hideClose: true,
              });
              break;
          }

          this.checkCaptainMarker();
        })
        .catch(this.handleError);
    },
    openedCellEmpty(data) {
      this.completeCell(data.target, data.cellIndex);
      this.$modal.show("message", {
        message:
          "Гермокапсула пуста. Продолжайте исследовать планету, чтобы найти задания и&nbsp;заполучить камень!",
      });
    },
    checkCaptainMarker() {
      GetIslandByIndex({ islandIdx: this.islandIndex }).then((resp) => {
        this.islandData = resp.data;

        this.islandData.cells.forEach((cell) => {
          if (cell.isCaptainsQuest) {
            const srcPath = this.islandDataStatic.cells[cell.cellIndex].path;
            const path = new fabric.Path(srcPath);
            this.addCaptainMarker(path);
          }
        });
      });
    },
    closeModalTask() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  &-back {
    position: absolute;
    top: 30px;
    left: 60px;
    z-index: 100;
    min-width: 216px;
  }
  &-legend {
    position: absolute;
    // top: calc(12vh + 55vh + 40px);
    top: calc(50vh + 15vw - 60px);
    left: 32.5vw;
    margin-left: -160px;
    z-index: 100;
    min-width: 216px;
  }
  &-learn {
    position: absolute;
    top: 30px;
    right: 60px;
    z-index: 100;
    min-width: 216px;
  }
}

.board {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 75%;
  z-index: 10;
  padding-top: 25px;
  padding-bottom: 60px;
  min-width: 640px;
  margin-top: -15px;
  transform: translateX(-50%) translateY(-50%) scale(0.8);
  @media (min-width: 1400px) {
  }
  @media (min-width: 1600px) {
    transform: translateX(-50%) translateY(-50%);
  }
  &::before {
    position: absolute;
    top: 107px;
    left: 107px;
    width: calc(100% - 214px);
    height: calc(100% - 214px);
    background-image: url(~@/assets/img/pic2/board.svg);
    background-repeat: no-repeat;
    background-position: -107px -107px;
    content: "";
    z-index: -1;
  }
  &__inner {
    text-align: center;
    width: 100%;
    padding: 0 50px 0 50px;
    &::before {
      position: absolute;
      top: 0px;
      left: 107px;
      width: calc(100% - 214px);
      height: 107px;
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      background-position: 50% 0;
      content: "";
      z-index: -1;
    }
    &::after {
      position: absolute;
      bottom: 0px;
      left: 107px;
      width: calc(100% - 214px);
      height: 107px;
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      background-position: 50% 100%;
      content: "";
      z-index: -1;
    }
  }
  &-head {
    // background-image: url(~@/assets/img/pic2/board.svg);
    // background-repeat: no-repeat;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 107px;
      height: 107px;
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      content: "";
      z-index: -1;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 107px;
      height: 107px;
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      background-position: 100% 0;
      content: "";
      z-index: -1;
    }
    &__group {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &__title {
      font-size: 54px;
      color: #5cffed;
    }
    &__button {
      @include reset-button;
      font-weight: 700;
      font-size: 20px;
      text-decoration-line: underline;
      color: #5effff;
      margin-bottom: 12px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-body {
    margin-top: 25px;
    &::before {
      position: absolute;
      top: 107px;
      left: 0;
      width: 107px;
      height: calc(100% - 214px);
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      background-position: 0 -107px;
      content: "";
      z-index: -1;
    }
    &::after {
      position: absolute;
      top: 107px;
      right: 0;
      width: 107px;
      height: calc(100% - 214px);
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      background-position: 100% -107px;
      content: "";
      z-index: -1;
    }
    &__title {
      font-weight: 700;
      font-size: 20px;
      color: #ade5d9;
      text-align: left;
    }
    &__list {
      margin-bottom: 40px;
      ul {
        padding: 0;
        list-style: none;
        li {
          display: grid;
          grid-template-columns: auto 1fr auto;
          align-items: center;
          margin: 20px 0;
          span {
            font-size: 18px;

            color: #ade5d9;
            b {
              font-size: 18px;
              font-weight: normal;
              color: #50ffeb;
              filter: drop-shadow(0px 0px 47px rgba(255, 243, 243, 0.25))
                drop-shadow(0px 0px 16px #00ffff);
            }
          }
          i {
            height: 1px;
            background-color: #032c29;
            margin: 0 25px;
          }
          b {
            font-family: $fontRafale;
            font-size: 16px;
            font-weight: normal;
          }
          strong {
            font-size: 18px;
            font-weight: normal;
            color: #50ffeb;
            margin-left: -18px;
            filter: drop-shadow(0px 0px 47px rgba(255, 243, 243, 0.25))
              drop-shadow(0px 0px 16px #00ffff);
          }
        }
      }
    }
  }
  &-foot {
    background-image: url(~@/assets/img/pic2/board-foot.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: 107px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 107px;
      height: 107px;
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      background-position: 0 100%;
      content: "";
      z-index: -1;
    }
    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 107px;
      height: 107px;
      background-image: url(~@/assets/img/pic2/board.svg);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      content: "";
      z-index: -1;
    }
    &__caption {
      font-size: 15px;
      line-height: 28px;
      color: #ade5d9;
      font-weight: normal;
      strong {
        font-weight: normal;
        color: #fff;
      }
    }
    &__title {
      font-family: $fontRafale;
      font-size: 24px;
      margin-top: 5px;
    }
  }
}
</style>
